/* #movie-titles {
    position:relative;
    animation: scrolling 7s linear 0s infinite; 
  } */
  .scroll-animate-fast {
    position:relative;
    animation: scrolling-fast 10s linear 0s infinite;
  }

  /* .colors {
    color:#f1d385;
    color:#2b2a2a;
    color: #6ebf8b;
  } */

  #rating-slider::-webkit-slider-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: #f1d385;
    border-radius: 50%;
    border: 1px solid #f1d385;
    cursor:pointer;
  }
